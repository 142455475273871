import * as React from 'react';
import { useEffect, useState } from 'react';
import BaseElement from '../../CustomElements/BaseElement';
import { useI18n } from '../../../Translation';
import { useCourseProgress } from './Hooks/useCourseProgess';
import { useUserDefaultCourse } from './Hooks/useUserDefaultCourse';
import { useCourse } from './Hooks/useCourse';
const CourseProgressbar = (props) => {
    const { isLoading: isCourseProgressLoading, data: courseProgress } = useCourseProgress(props.courseUuid);
    const { isLoading: isDefaultCourseLoading, data: defaultCourseData } = useUserDefaultCourse();
    const [finishedInPercent, setFinishedInPercent] = useState(0);
    const [finished, setFinished] = useState(0);
    const [total, setTotal] = useState(0);
    useEffect(() => {
        if (!isCourseProgressLoading && courseProgress && courseProgress.progress) {
            const { finished, total, finishedInPercent } = courseProgress.progress;
            setFinished(finished);
            setTotal(total);
            setFinishedInPercent(finishedInPercent);
        }
    }, [isCourseProgressLoading, isDefaultCourseLoading, courseProgress, isDefaultCourseLoading, defaultCourseData]);
    if (props.courseUuid && !isCourseProgressLoading && courseProgress) {
        return <CourseProgress defaultCourseData={{ uuid: props.courseUuid }} finishedInPercent={finishedInPercent} finished={finished} total={total}/>;
    }
    return <></>;
};
const CourseProgress = (props) => {
    const { data: course } = useCourse(props.defaultCourseData.uuid);
    const i18n = useI18n();
    const roundedPercent = Math.round(props.finishedInPercent);
    return <div className="relative mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 lg:max-w-7xl flex flex-nowrap items-center bg-white shadow-xl rounded-2xl h-20">
        <div className="rounded-full flex justify-center items-center bg-white -m-6 p-2 ">
            <CircleProgress progress={roundedPercent}/>
            <span className="absolute text-2xl text-brand-primary">{roundedPercent}%</span>
        </div>
        <div className="ml-10 flex flex-col w-full space-y-4">
            <div className="flex justify-between items-center">
                <p className="font-semibold text-gray-600 text-lg sm:text-xl">{i18n['course.fullprogress']} {course?.title}</p>
                <span className="ml-auto text-xl font-medium text-brand-primary hidden sm:block">{props.finished}/{props.total} {i18n['course.lectures']}</span>
                <a href={course?.pageLink || '#'}>
                    <span className="absolute inset-0"></span>
                </a>
            </div>
        </div>
    </div>;
};
CourseProgressbar.displayName = 'CourseProgressbar';
export default CourseProgressbar;
class CourseProgressbarElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = CourseProgressbar;
    }
}
export const loadCourseProgressbarElement = () => {
    customElements.define('course-progressbar-element', CourseProgressbarElement);
};
const CircleProgress = ({ progress, size = 100, strokeWidth = 10 }) => {
    const center = size / 2;
    const radius = center - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;
    return (<svg width={size} height={size} className="transform -rotate-90">
            <circle cx={center} cy={center} r={radius} stroke="currentColor" strokeWidth={strokeWidth} className="text-gray-300" fill="none"/>
            <circle cx={center} cy={center} r={radius} stroke="currentColor" strokeWidth={strokeWidth} className="text-brand-primary" fill="none" strokeDasharray={circumference} strokeDashoffset={offset} style={{ transition: 'stroke-dashoffset 0.35s' }} strokeLinecap="round"/>
        </svg>);
};
