import fetch from 'isomorphic-unfetch';
export const request = (uri, init) => {
    const host = `https://${window.location.hostname}`;
    return fetch(`${host}${uri}`, init);
};
export const getLinks = async () => {
    const response = await request('/api/products/');
    return await response.json();
};
export const getTags = async () => {
    const response = await request('/api/tags/');
    return await response.json();
};
export const getQuestions = async (questionId = null) => {
    const response = await request(`/api/questions/${questionId}`);
    return await response.json();
};
export const getMediaElement = (id, type, thumbnail) => `/${type}/el/${id}${thumbnail ? '?thumbnail=true' : ''}`;
export const getLiveVideos = async (liveplayerTypeId) => {
    const response = await request(generateUrl('sitzkrieger_livestream_streamislive_tagged', {
        tag: liveplayerTypeId,
    }));
    return await response.json();
};
export const getVideoSeries = async (id) => {
    const response = await request(`/api/videoseries/${id}`);
    return await response.json();
};
export const getChallenge = async (id) => {
    const response = await request(`/api/challenge/${id}`);
    return await response.json();
};
export const getGuides = async () => {
    const response = await request('/api/guides/');
    return await response.json();
};
export const getSingleGuide = async (guideId) => {
    const response = await request('/api/guides/' + guideId);
    return await response.json();
};
export const getPostcards = async (id) => {
    const response = await request('/api/postcard/' + id);
    const json = await response.json();
    return json.map((postcard) => {
        return {
            ...postcard,
            'frontSrc': postcard.front ? getMediaElement(postcard.front, 'i') : '',
            'backSrc': postcard.back ? getMediaElement(postcard.back, 'i') : '',
        };
    });
};
export const interactWithFavouritePlaylist = async (id, interaction) => {
    const response = await request(`/api/playlist/favourites/${interaction}?mediaElementId=${id}`, {
        method: 'POST',
    });
    return await response.json();
};
export const interactWithFavouritePlaylistForCourse = async (courseUuid, id, interaction) => {
    const response = await request(generateUrl(interaction === 'add' ?
        'sitzkrieger_playlist_addvideotofavouritesforcourse' :
        'sitzkrieger_playlist_removevideofromfavouritesforcourse', {
        'courseUuid': courseUuid,
        'mediaElementId': id,
    }), {
        method: 'POST',
    });
    return await response.json();
};
export const getCommentsForElement = async (type, element) => {
    const response = await request(`/api/comments/${type}/${element}`);
    return await response.json();
};
export const voteOnComment = async (id, reaction) => {
    return await request(`/api/comments/vote/${id}/${reaction}`)
        .then((res) => res.json());
};
export const setCompanyColor = async (type, color) => {
    return await request(`/api/company/colors/${type}`, {
        method: 'POST',
        body: JSON.stringify(color),
    })
        .then((res) => res.json());
};
export const getContactRequestReasons = async () => {
    const response = await request(generateUrl('contact_request_reasons'));
    return await response.json();
};
export const createContactRequest = async (data) => {
    const response = await request(generateUrl('create_contact_request'), {
        method: 'POST',
        body: JSON.stringify(data),
    });
    return await response.json();
};
export const videoStatisticsStart = async (videoId, videoSeriesId = null) => {
    const params = { videoId };
    if (videoSeriesId) {
        params['vs'] = videoSeriesId.toString();
    }
    const response = await request(generateUrl('sitzkrieger_v2_videostatsapi_startvideo', params), {
        method: 'GET'
    });
    if (response.status === 200) {
        return await response.text();
    }
    return '';
};
export const videoStatisticsWatch = async (userVideoId, videoId, elapsed, videoSeriesId = null) => {
    const params = { videoId, userVideoId, elapsed };
    if (videoSeriesId) {
        params['vs'] = videoSeriesId.toString();
    }
    await request(generateUrl('sitzkrieger_v2_videostatsapi_watchvideo', params), {
        method: 'HEAD'
    });
};
export const videoStatisticsFinish = async (userVideoId, videoId, videoSeriesId = null) => {
    const params = { videoId, userVideoId };
    if (videoSeriesId) {
        params['vs'] = videoSeriesId.toString();
    }
    await request(generateUrl('sitzkrieger_v2_videostatsapi_finishvideo', params), {
        method: 'HEAD'
    });
};
export const myAreaStatsWatchtime = async () => {
    const response = await request(generateUrl('sitzkrieger_v2_myareaapi_totalwatchedminutes'), {
        method: 'GET'
    });
    return await response.json();
};
export const myAreaStatsWatchedVideos = async () => {
    const response = await request(generateUrl('sitzkrieger_v2_myareaapi_totalwatchedvideos'), {
        method: 'GET'
    });
    return await response.json();
};
export const myAreaStatsActiveDays = async () => {
    const response = await request(generateUrl('sitzkrieger_v2_myareaapi_activedays'), {
        method: 'GET'
    });
    return await response.json();
};
export const myAreaStatsHealthScore = async () => {
    const response = await request(generateUrl('sitzkrieger_v2_myareaapi_healthscore'), {
        method: 'GET'
    });
    if (response.status === 200) {
        return await response.json();
    }
    if (response.status === 404) {
        return 404;
    }
    throw new Error(await response.text());
};
export const myAreaVideoCategoryAccess = async () => {
    const response = await request(generateUrl('sitzkrieger_v2_myareaapi_video_category_access'), {
        method: 'GET'
    });
    if (response.status === 200) {
        return await response.json();
    }
    throw new Error(await response.text());
};
export const subscribeToNotification = async (key, subscribe) => {
    const url = !subscribe ? 'app_notificationsubscription_subscribe' : 'app_notificationsubscription_unsubscribe';
    const response = await request(generateUrl(url, { type: key }));
    if (response.status === 200) {
        return await response.json();
    }
    throw new Error(await response.text());
};
export const activateModule = async (module) => {
    const response = await request(generateUrl('sitzkrieger_module_activate_module', { moduleId: module }));
    if (response.status === 200) {
        return await response.json();
    }
    throw new Error(await response.text());
};
export const getCourseDetail = async (courseUuid, useAdminRoutes) => {
    let prefix = '';
    if (useAdminRoutes) {
        prefix = '/admin/sitzkrieger';
    }
    return await request(`${prefix}/courses/${courseUuid}`)
        .then((res) => res.json());
};
export const getCourseModulesList = async (courseUuid, useAdminRoutes) => {
    let prefix = '';
    if (useAdminRoutes) {
        prefix = '/admin/sitzkrieger';
    }
    return await request(`${prefix}/courses/${courseUuid}/modules`)
        .then((res) => res.json());
};
export const getCourseModuleLectures = async (courseUuid, moduleUuid, useAdminRoutes) => {
    let prefix = '';
    if (useAdminRoutes) {
        prefix = '/admin/sitzkrieger';
    }
    return await request(`${prefix}/courses/${courseUuid}/modules/${moduleUuid}/lectures`)
        .then((res) => res.json());
};
export const getLectureDetail = async (courseUuid, moduleUuid, lectureUuid, useAdminRoutes) => {
    let prefix = '';
    if (useAdminRoutes) {
        prefix = '/admin/sitzkrieger';
    }
    return await request(`${prefix}/courses/${courseUuid}/modules/${moduleUuid}/lectures/${lectureUuid}`)
        .then((res) => res.json());
};
export const getMediaElementObject = async (mediaElementId) => {
    return await request(`/api/mediaelement/${mediaElementId}`)
        .then((res) => res.json());
};
export const getCourseProgress = async (courseUuid) => {
    return await request(`/my-courses/progress/${courseUuid}`)
        .then((res) => res.json());
};
export const getUserDefaultCourse = async () => {
    return await request('/my-courses/default')
        .then((res) => res.json());
};
export const updateCourseProgress = async (courseUuid, lectureUuid) => {
    return await request(`/my-courses/progress/${courseUuid}/${lectureUuid}`)
        .then((res) => res.json());
};
/**
 * this allows using FosRoutingBundle Routing in a way, that it supports typescript-ish
 * @param {string} route
 * @param params
 */
export const generateUrl = (route, params = {}) => {
    // @ts-ignore
    return Routing.generate(route, params);
};
