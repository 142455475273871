import * as React from 'react';
import { generateUrl, interactWithFavouritePlaylist } from '../../../Utilities/api';
import { toast } from 'react-hot-toast';
export const FavouriteButton = ({ infavorites, videoid, customFavouriteApiCallable }) => {
    const [isInFavourites, setIsInFavourites] = React.useState(!!infavorites);
    return <button className="inline-flex items-center gap-x-1.5 rounded-md shadow-sm bg-white px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 hover:ring-gray-600" onClick={async (event) => {
            event.preventDefault();
            const json = customFavouriteApiCallable ? await customFavouriteApiCallable(videoid, isInFavourites ? 'remove' : 'add') : await interactWithFavouritePlaylist(videoid, isInFavourites ? 'remove' : 'add');
            if (json.success) {
                setIsInFavourites(!isInFavourites);
                toast.success(isInFavourites ? 'Erfolgreich aus den Favoriten entfernt' : <div>Erfolgreich zu den Favoriten hinzugefügt <div className="mt-3 flex justify-end"><a className="text-brand-primary" href={generateUrl('app_myarea_dashboard')}>Zu meinem Bereich</a></div></div>);
            }
            else {
                if (json?.status === 401) {
                    toast.error('Du musst eingeloggt sein, um Videos zu den Favoriten hinzufügen zu können.');
                }
                else {
                    toast.error('Es ist ein Fehler aufgetreten');
                }
            }
        }}>
        {isInFavourites
            ? <i className="fa-solid fa-star text-brand-red0"></i>
            : <i className="fa-solid fa-star"></i>}

        <span>
            {isInFavourites
            ? 'Aus Favoriten entfernen'
            : 'Zu Favoriten hinzufügen'}
        </span>
    </button>;
};
FavouriteButton.displayName = 'FavouriteButton';
export default FavouriteButton;
