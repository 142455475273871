import * as React from 'react';
import { useState } from 'react';
import BaseElement from '../../CustomElements/BaseElement';
import CourseModuleList from './CourseModuleList';
import { useI18n } from '../../../Translation';
import Spinner from '../Spinner';
import parse from 'html-react-parser';
import { useCourse } from './Hooks/useCourse';
import { useCourseProgress } from './Hooks/useCourseProgess';
import Confetti from 'react-confetti';
import { useInterval } from '../../../Utilities/customHooks';
import { generateUrl } from '../../../Utilities/api';
import { AdminRoutesContext } from './AdminRoutesContext';
import { ProfileContext } from '../Profile/ProfileVideoCategoryContainer';
import ProfileVideoCategory from '../Profile/Partials/ProfileVideoCategory';
import StarIconCube from '../Profile/IconCubes/StarIconCube';
import ProfileVideoModal from '../Profile/Partials/ProfileVideoModal';
const Course = (props) => {
    const { isLoading, data: course } = useCourse(props.courseUuid);
    const { isLoading: isCourseProgressLoading, data: courseProgress } = useCourseProgress(props.courseUuid);
    const [showConfetti, setShowConfetti] = useState(true);
    const [documentsReRequested, setDocumentsReRequested] = useState(false);
    const i18n = useI18n();
    const [mediaElements, setMediaElements] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState({ identifier: '', title: '', iconCube: <></>, description: '', endpoint: '', notFoundText: '' });
    const handleOpen = () => {
        setIsOpen(!isOpen);
    };
    useInterval(() => {
        if (!isCourseProgressLoading && courseProgress && (courseProgress.progress.total === courseProgress.progress.finished)) {
            setShowConfetti(false);
        }
    }, 10 * 1000);
    if (isLoading) {
        return <Spinner />;
    }
    if (!course) {
        return <div>{i18n['course.courseNotFound']}</div>;
    }
    return <>
        {props.showTextBlocks && (<>
                <h1 className="mb-2 text-3xl font-bold leading-7 text-gray-900 sm:text-4xl sm:tracking-tight">
                    {course.title}
                </h1>
                <p className="mb-4 text-lg leading-8 text-gray-600">
                    {course.subtitle}
                </p>
            </>)}

        <div className={'my-4'}>
            <ProfileContext.Provider value={{
            isOpen,
            isFetching: false,
            mediaElements,
            currentCategory,
            handleOpen,
            setCurrentCategory,
            setMediaElements
        }}>
                <ProfileVideoCategory showArrow={false} hasBackgroundBanner={false} category={{ identifier: 'course', title: i18n['profile.videoCategories.courseFavourites.heading'], iconCube: <StarIconCube />, description: '', endpoint: generateUrl('sitzkrieger_v2_myareaapi_favouritevideosforcourse', { courseUuid: props.courseUuid }), 'notFoundText': i18n['profile.videoCategories.favourites.notFound'] }}/>
                <ProfileVideoModal />
            </ProfileContext.Provider>
        </div>

        {!isCourseProgressLoading && courseProgress && (courseProgress.progress.total === courseProgress.progress.finished) ? <>
            <>
                <p className="mb-4 text-lg leading-8 text-gray-600">
                    {showConfetti ? <Confetti width={window.innerWidth} height={window.innerHeight} gravity={0.05} run={showConfetti}/> : null}
                    Du hast den Kurs erfolgreich abgeschlossen.<br />
                    Dir wurde die Teilnahmebescheinigung per Mail geschickt.
                </p>
                <div className="flex">
                    <button onClick={() => {
                setDocumentsReRequested(true);
                fetch(generateUrl('sitzkriegercourses_mycourse_rerequestcoursefinisheddocuments', { courseUuid: props.courseUuid }))
                    .then((res) => res.json());
            }} disabled={documentsReRequested} className="inline-flex items-center gap-x-2 rounded-md bg-gray-100 px-3.5 py-2.5 text-base font-semibold text-gray-900 shadow-sm md:bottom-24 md:right-12">Bescheinigung
                        erneut zuschicken
                    </button>

                    <button onClick={() => {
                fetch(generateUrl('sitzkriegercourses_mycourse_quitfinishedcourse', { courseUuid: props.courseUuid }))
                    .then((res) => res.json())
                    .then((res) => {
                    if (res.success) {
                        window.location.href = '/';
                    }
                });
            }} className="inline-flex items-center gap-x-2 rounded-md bg-gray-100 px-3.5 py-2.5 text-base font-semibold text-gray-900 shadow-sm ml-2.5 md:bottom-24 md:right-12">
                        Kurs-Modus beenden
                    </button>
                </div>
            </>
        </> : null}

        {props.showTextBlocks && course.preLecturesContent && (<section className="mb-4">
                {parse(course.preLecturesContent)}
            </section>)}

        <section className="mb-4">
            <CourseModuleList courseUuid={props.courseUuid} courseOverviewLink={course.pageLink}/>
        </section>


        {props.showTextBlocks && course.postLecturesContent && (<section className="mb-4">
                {parse(course.postLecturesContent)}
            </section>)}
    </>;
};
const CourseWrapper = (props) => {
    return <AdminRoutesContext.Provider value={{ useAdminRoutes: props.useAdminRoutes }}>
        <Course {...props}/>
    </AdminRoutesContext.Provider>;
};
Course.displayName = 'Course';
export default Course;
class CourseElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = CourseWrapper;
    }
}
export const loadCourseElement = () => {
    customElements.define('course-element', CourseElement);
};
