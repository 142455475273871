export const DE_DE = {
    'profile.profileVideoIntroHeading': 'Deine Videos',
    'profile.profileVideoIntro': 'Hier findest du Empfehlungen, Favoriten und\n zuletzt angesehene Videos – alles nach deinem Geschmack. Entdecke Neues, behalte deine\n Lieblingsvideos im Blick und setze deine Videoreise nahtlos fort. Tauche ein und erlebe Videos,\n die speziell für dich ausgewählt wurden!',
    'profile.profileScoresIntroHeading': 'Deine Scores',
    'profile.profileScoresIntro': 'Hier hast du die Möglichkeit, einen umfassenden Einblick in deine Gesundheit, Fitness und Gleichgewichtsfähigkeit zu erhalten. Unsere maßgeschneiderten Tests und Bewertungen ermöglichen es dir, deine Fortschritte und Bereiche mit Optimierungspotenzial besser zu verstehen.',
    'profile.profileModulesHeading': 'Deine aktivierten Module',
    'profile.videoCategories.healthScore.heading': 'Deine Health Score Empfehlungen',
    'profile.videoCategories.healthScore.description': 'Optimiere deine Gesundheit - Hier sind deine personalisierten Empfehlungen!',
    'profile.videoCategories.healthScore.notFound': 'Es sieht so aus, als ob du noch keinen Health Score abgeschlossen hast. Führe jetzt deinen Health Score durch und erhalte hier eine für dich persönlich zusammengestellte Videoplaylist.',
    'profile.videoCategories.history.heading': 'Zuletzt angeschaute Videos',
    'profile.videoCategories.history.description': 'Deine Inspiration von gestern für den Erfolg von morgen.',
    'profile.videoCategories.history.notFound': 'Hoppla, du scheinst noch keine Videos auf Sitzkrieger angeschaut zu haben.',
    'profile.videoCategories.favourites.heading': 'Deine Favoriten',
    'profile.videoCategories.favourites.description': 'Immer griffbereit - Deine Lieblingsinhalte an einem Ort!',
    'profile.videoCategories.favourites.notFound': 'Es sieht so aus, als ob du noch keine Videos zu deinen Favoriten hinzugefügt hast. Keine Sorge, du kannst später welche hinzufügen!',
    'profile.videoCategories.courseFavourites.heading': 'Deine Favoriten für diesen Kurs',
    'profile.videoCategories.weekly.heading': 'Deine wöchentlichen Empfehlungen',
    'profile.videoCategories.weekly.description': 'Entdecke die besten Video-Highlights der Woche: Deine wöchentlichen Empfehlungen!',
    'profile.videoCategories.weekly.notFound': 'In dieser Woche haben wir keine speziellen Empfehlungen für dich. Wir hoffen, bald wieder passende Videos für dich zu haben.',
    'profile.videoCategories.trainer.heading': 'Deine Trainerempfehlungen',
    'profile.videoCategories.trainer.description': 'Ganz auf dich abgestimmt - Unsere Top-Trainer für dein individuelles Training!',
    'profile.videoCategories.trainer.notFound': 'Aktuell haben wir keine Videos von deinem Personal Trainer zur Verfügung. Bitte schau später wieder vorbei, um neue Empfehlungen zu erhalten.',
    'profile.healthScore.startTest': 'Test starten',
    'profile.healthScore.startTestNow': 'Jetzt Test starten',
    'profile.healthScore.showReport': 'Bericht anzeigen',
    'profile.healthScore.errorWhileLoading': 'Fehler beim Laden',
    'profile.healthScore.noTestYet': 'Es wurde bisher kein Test durchgeführt',
    'profile.healthScore.resultDate': (date) => `Ergebnis vom ${date}`,
    'profile.modal.close': 'Schließen',
    'profile.modal.errorOccurred': 'Ein Fehler ist aufgetreten',
    'profile.modal.videoDetail.videoLength': 'Videolänge',
    'profile.modal.videoDetail.videoPublished': 'Veröffentlicht',
    'profile.modal.videoDetail.videoContents': 'Inhalte',
    'profile.modal.videoDetail.videoEquipment': 'Geräte',
    'profile.modules.availableSince': 'Verfügbar ab',
    'profile.modules.landingPageUrl': 'Mehr Infos',
    'challenge.modal.normalUser.title': 'Gutes neues Jahr!!',
    'challenge.modal.normalUser.description': '<p>Wir haben dir ein Geschenk unter deinen Weihnachtsbaum gelegt:</p><br/>' +
        '<p class="font-bold text-lg">3-Monate Sitzkrieger Premiumzugang!*</p><br/>' +
        '<p>Seit 24 Tagen baust du die Gewohnheit auf, dich mit deiner Gesundheit zu beschäftigen. Nutze diesen Schwung und nimm ihn mit ins neue Jahr.</p><br/>' +
        '<p>Entdecke zahlreiche Angebote, die dich in Bewegung halten, deinen Schlaf verbessern, Schmerzen reduzieren und vieles mehr. Alles kostenlos!</p><br/>' +
        '<p>Hier könntest du starten: Teste deine Gesundheit, mit unserem HealthScore und erfahre wie du am besten weitermachen kannst.</p><br/>' +
        '<p>Oder besuche unsere aktive Pause! Gleich heute um 12 Uhr (und auch an jedem anderen Tag).</p><br/>' +
        '<p class="text-gray-500">*Für deinen Premiumzugang ist keine weitere Anmeldung erforderlich, du kannst es direkt nutzen! Dein Premiumzugang endet automatisch am 31.03.24 und bedarf keiner aktiven Kündigung von dir.</p>',
    'challenge.modal.normalUser.buttonTitle': 'Jetzt den Premiumbereich entdecken',
    'challenge.modal.normalUser.buttonLink': '#',
    'challenge.modal.merckUser.title': 'Gutes neues Jahr!!',
    'challenge.modal.merckUser.description': '<p>Wir haben dir ein Geschenk unter deinen Weihnachtsbaum gelegt:</p><br/>' +
        '<p class="font-bold text-lg">die ultimative 21-Tage-Fitnesschallenge!</p><br/>' +
        '<p>Seit 24 Tagen baust du die Gewohnheit auf, dich mit deiner Gesundheit zu beschäftigen. Nutze diesen Schwung und nimm ihn mit ins neue Jahr. Dafür haben wir uns etwas Besonderes überlegt:</p><br/>' +
        '<p>•\tAm <b>15.01.24</b> findet unser <b>Live-Webinar</b> zum Thema <b>"Gesunde Vorsätze"</b> statt. In diesem Webinar erfährst du, wie du deine Neujahrsvorsätze endlich mit Leichtigkeit umsetzt.</p><br/>' +
        '<p>•\tDirekt im Anschluss startet unsere <b>21-Tage Fitness-Challenge</b>. Jeden Tag erwartet dich ein neues aufregendes Übungsvideo, das deine Fitness auf ein neues Level hebt.</p><br/>' +
        '<p>Kannst du dir vorstellen, was passiert, wenn du dich 21 Tage voll auf deine Fitness konzentrierst? Finde es mit uns heraus und melde dich direkt an.</p><br/>',
    'challenge.modal.merckUser.buttonTitle': 'Jetzt anmelden',
    'challenge.modal.merckUser.buttonLink': '#',
    'profile.account.delete-button.title': 'Account löschen',
    'profile.account.delete-button.no-permission': 'Du musst angemeldet sein, um dein Account löschen zu können.',
    'profile.account.delete-modal.title': 'Deinen Sitzkrieger-Account löschen',
    'profile.account.delete-modal.button-title': 'Account löschen',
    'profile.account.delete-modal.description': 'Hinweis: Das Löschen wird erst nach 30 Tagen vollzogen. In der Zeit kannst Du dich weiterhin einloggen. Möchtest du, dass dieser Vorgang abgebrochen wird, kontaktiere den Support.',
    'profile.account.delete-modal.confirm-text': 'Zur Bestätigung der Löschanfrage bitte einmal deinen Benutzernamen eingeben',
    'course.courseNotFound': 'Kurs wurde nicht gefunden',
    'course.finishLecture': 'Einheit abschließen',
    'course.backToOverview': 'Zurück zur Übersicht',
    'course.explorePlatform': 'Plattform entdecken',
    'course.viewContent': 'Inhalte ansehen',
    'course.lectureContent': 'Einheit Inhalt',
    'course.lectures': 'Einheiten',
    'course.startLecture': 'Einheit starten',
    'course.lecturesNotFound': 'Keine Einheiten verfügbar',
    'course.lectureNotFound': 'Einheit wurde nicht gefunden',
    'course.goToWebinar': 'Webinar Termin herunterladen',
    'course.mediaElementError': 'Das Video konnte nicht geladen werden',
    'course.progress': 'Fortschritt',
    'course.finishedLecture': 'Einheit abgeschlossen',
    'course.lectureLocked': 'Einheit gesperrt',
    'course.lectureLockedPreviousOpen': 'letzte Einheit abschließen',
    'course.lectureLockedAvailableIn': (days) => days > 1 ? (`noch ${days} Tage`) : 'noch 1 Tag',
    'course.fullprogress': 'Gesamtfortschritt',
    'course.video.duration.issue': 'Schaue bitte das Video vollständig, um die Einheit abschließen zu können',
    'course.watchtime.threshold': 'Schaue bitte das Video vollständig, um die Einheit abschließen zu können',
    'courses.activatedCoursesHeading': 'Aktivierte Kurse',
    'course.mycourse.activatedSince': 'Aktiviert am',
    'course.goToCourse': 'Zum Kurs',
    'course.activated': 'Dein aktiver Kurs',
    'course.startTraining': 'Jetzt trainieren',
};
