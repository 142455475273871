import * as React from 'react';
import { useEffect, useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import parse from 'html-react-parser';
import { useI18n } from '../../../Translation';
import { getMediaElementObject, interactWithFavouritePlaylistForCourse } from '../../../Utilities/api';
import { toast } from 'react-hot-toast';
import VideoPlayer from '../VideoPlayer';
import Spinner from '../Spinner';
import { FavouriteButton } from '../Mediathek/FavoriteButton';
const CourseLectureItem = ({ courseUuid, courseLectureItem, open }) => {
    const renderLectureItemType = () => {
        switch (courseLectureItem.courseLectureItemTypeEnum) {
            case 'html':
                return <LectureTypeHTML json={courseLectureItem.json}/>;
            case 'video':
                return <LectureTypeVideo json={courseLectureItem.json} courseUuid={courseUuid}/>;
            case 'webinar':
                return <LectureTypeWebinar json={courseLectureItem.json}/>;
            default:
                return <></>;
        }
    };
    return (<Disclosure as="div" defaultOpen={open}>
            {({ open }) => (<div className="relative flex gap-x-4">
                    <div className="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                        <div className="w-px bg-gray-200"></div>
                    </div>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                        <div className="h-3 w-3 rounded-full bg-gray-200"></div>
                    </div>
                    <div className="hover:bg-gray-50 flex-auto rounded-lg ring-1 ring-inset ring-gray-200 overflow-hidden">
                        <Disclosure.Button className="w-full text-left px-4 py-5 sm:px-6">
                            <h3 className="text-xl font-semibold leading-6 text-gray-900">{courseLectureItem.title}</h3>
                            <p className="text-base leading-6 text-gray-500">
                                {courseLectureItem.subtitle}
                            </p>
                        </Disclosure.Button>
                        <Transition enter="transition duration-100 ease-out" enterFrom="transform scale-95 opacity-0" enterTo="transform scale-100 opacity-100" leave="transition duration-75 ease-out" leaveFrom="transform scale-100 opacity-100" leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel as="div" className="px-4 pb-5 sm:px-6">
                                {renderLectureItemType()}
                            </Disclosure.Panel>
                        </Transition>
                    </div>
                </div>)}
        </Disclosure>);
};
CourseLectureItem.displayName = 'LectureItem';
export default CourseLectureItem;
const LectureTypeHTML = ({ json }) => {
    const jsonObject = JSON.parse(json);
    return (<>
            {parse(jsonObject.content || '')}
        </>);
};
const LectureTypeVideo = ({ courseUuid, json }) => {
    const i18n = useI18n();
    const [mediaElement, setMediaElement] = useState(null);
    const [loading, setLoading] = useState(true);
    const jsonObject = JSON.parse(json);
    useEffect(() => {
        loadMediaElement();
    }, []);
    const loadMediaElement = () => {
        getMediaElementObject(jsonObject.mediaElementId).then((res) => {
            if (res.success === false) {
                toast.error(res.message);
            }
            else {
                setMediaElement(res);
            }
            setLoading(false);
        });
    };
    if (loading) {
        return <Spinner />;
    }
    if (!mediaElement) {
        return <div>{i18n['course.mediaElementError']}</div>;
    }
    return (<>
            <div className="relative w-full lg:w-9/12 rounded-lg aspect-video">
                <VideoPlayer {...mediaElement} id={parseInt(jsonObject.mediaElementId)} controls={true} playing={true} muted={false} responsive={false} preview={true} height={'100%'} withMargin={false} thumbnailWidth="1080"/>
            </div>
            <div className="mb-4 flex items-center">
                <FavouriteButton infavorites={mediaElement.inFavourites || false} videoid={Number(mediaElement.id)} customFavouriteApiCallable={(videoid, interaction) => interactWithFavouritePlaylistForCourse(courseUuid, videoid, interaction)}/>
            </div>
        </>);
};
const LectureTypeWebinar = ({ json }) => {
    const i18n = useI18n();
    const jsonObject = JSON.parse(json);
    return (<>
            <div className="flex flex-col gap-y-4">
                <div>
                    {parse(jsonObject.content || '')}
                </div>

                <div>
                    <a href={jsonObject.webinarLink} target="_blank" className="inline-flex items-center gap-x-2 rounded-md bg-brand-primary px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-brand-secondary md:bottom-24 md:right-12" rel="noreferrer">
                        {i18n['course.goToWebinar']}
                    </a>
                </div>
            </div>
        </>);
};
