import * as React from 'react';
import { ProfileContext } from '../ProfileVideoCategoryContainer';
const ProfileVideoCategory = ({ category, hasBackgroundBanner = true, showArrow = true, }) => {
    const { handleOpen, setCurrentCategory } = React.useContext(ProfileContext);
    const handleClick = () => {
        setCurrentCategory(category);
        handleOpen();
    };
    return <li className="flow-root">
        <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-[#00537e] hover:bg-gray-900/20 hover:cursor-pointer">
            {category.iconCube}
            <div>
                <h3 className={'text-lg font-medium ' + (hasBackgroundBanner ? 'text-white' : 'text-black')}>
                    <span onClick={handleClick} className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true"></span>
                        <span>{category.title}</span>
                        {showArrow && (<span aria-hidden="true"> &rarr;</span>)}
                    </span>
                </h3>
                <p className="mt-1 text-sm text-gray-200">
                    {category.description}
                </p>
            </div>
        </div>
    </li>;
};
ProfileVideoCategory.displayName = 'ProfileVideoCategory';
export default ProfileVideoCategory;
