import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { useI18n } from '../../../Translation';
import Spinner from '../Spinner';
import { useCourseModuleLectures } from './Hooks/useCourseModuleLectures';
import { useCourseProgress } from './Hooks/useCourseProgess';
import { AdminRoutesContext } from './AdminRoutesContext';
const CourseModuleItem = (props) => {
    const { useAdminRoutes } = useContext(AdminRoutesContext);
    const { isLoading, data: courseModuleLectures } = useCourseModuleLectures(props.courseUuid, props.courseModule.uuid);
    const { isLoading: isCourseProgressLoading, data: courseProgress } = useCourseProgress(props.courseUuid);
    const [isFinished, setIsFinished] = useState(false);
    const [finishedModuleLectures, setFinishedModuleLectures] = useState([]);
    const [finishedInPercent, setFinishedInPercent] = useState(0);
    const [count, setCount] = useState(0);
    const [total, setTotal] = useState(0);
    const i18n = useI18n();
    const urlPrefix = useAdminRoutes ? '/admin/sitzkrieger' : '';
    useEffect(() => {
        if (useAdminRoutes) {
            return;
        }
        if (!isCourseProgressLoading && courseProgress && courseProgress.progress && !isLoading && courseModuleLectures) {
            const moduleProgress = courseProgress.progress.perModules[props.courseModule.uuid];
            setTotal(courseModuleLectures.length);
            if (moduleProgress) {
                const { count, total, finishedLectures } = moduleProgress;
                setIsFinished(count === total);
                setCount(count);
                setFinishedModuleLectures(finishedLectures);
                setFinishedInPercent((count / total) * 100);
            }
        }
    }, [isCourseProgressLoading, isLoading, courseModuleLectures, courseProgress, props.courseModule.uuid]);
    return <Disclosure as="div" className="relative rounded-lg bg-white border border-gray-300 overflow-hidden" defaultOpen={props.defaultOpen}>
        {({ open }) => (<>
                <Disclosure.Button className="flex flex-col gap-y-4 lg:gap-y-0 lg:flex-row w-full items-start justify-between text-left p-5 border-b border-gray-300 hover:bg-gray-50">
                    <div className="flex grow">
                        <div className="flex items-center gap-x-4">
                            {isLoading || courseModuleLectures === null ? <>
                                <Spinner />
                            </> : <>
                                <ItemStatus isLocked={courseModuleLectures[0].isLocked} isFinished={isFinished}/>
                                <span>{props.courseModule.title}</span>
                            </>}
                        </div>
                    </div>
                    <div className="flex-none flex items-center gap-x-4">
                        {isCourseProgressLoading ? (<Spinner />) : (<div className="flex items-center justify-between divide-x divide-gray-300">
                                {/*<span className="pr-2 text-base font-semibold leading-6">{Math.round(finishedInPercent)}% {i18n['course.progress']}</span>*/}
                                <span className="pl-2 text-base">{count}/{total} {i18n['course.lectures']}</span>
                            </div>)}
                    </div>
                </Disclosure.Button>
                <Transition enter="transition duration-100 ease-out" enterFrom="transform scale-95 opacity-0" enterTo="transform scale-100 opacity-100" leave="transition duration-75 ease-out" leaveFrom="transform scale-100 opacity-100" leaveTo="transform scale-95 opacity-0">
                    <Disclosure.Panel as="div">
                        <ul role="list" className="divide-y divide-gray-100">

                            {isLoading && (<li>
                                    <Spinner />
                                </li>)}

                            {!isLoading && courseModuleLectures && courseModuleLectures.length <= 0 && (<li>
                                    {i18n['course.lecturesNotFound']}
                                </li>)}

                            {!isLoading && courseModuleLectures && courseModuleLectures.length > 0 && (<li>
                                    <ul className="w-full divide-y divide-gray-300">
                                        {courseModuleLectures.map((courseModuleLecture) => (<li className="group relative flex items-center justify-between gap-x-6" key={courseModuleLecture.uuid}>
                                                <div className={'w-full pl-10 pr-5 py-5 group-hover:bg-gray-50' + (courseProgress?.currentLectureUuid === courseModuleLecture.uuid ? ' font-bold text-brand-primary bg-gray-200' : '')}>
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex items-center gap-x-4">
                                                            <ItemStatus isLocked={courseModuleLecture.isLocked} isFinished={finishedModuleLectures.includes(courseModuleLecture.uuid)}/>
                                                            <span className="truncate">{courseModuleLecture.title}</span>
                                                        </div>

                                                        {courseModuleLecture.isLocked ? (<>
                                                                {courseModuleLecture.previousIsLocked ? <span className="hidden lg:block">{i18n['course.lectureLocked']}</span> :
                            (courseModuleLecture.unlockedInDays && (Number(courseModuleLecture.unlockedInDays) > 0) ?
                                <span className="hidden lg:block">{i18n['course.lectureLockedAvailableIn'](courseModuleLecture.unlockedInDays)}</span>
                                : <span className="hidden lg:block">{i18n['course.lectureLockedPreviousOpen']}</span>)}
                                                            </>) : (<a href={`${urlPrefix}/course/${props.courseUuid}/modules/${props.courseModule.uuid}/lectures/${courseModuleLecture.uuid}?redirectUrl=${props.courseOverviewLink}`}>
                                                                <span aria-hidden="true" className="absolute inset-0"/>

                                                                {finishedModuleLectures.includes(courseModuleLecture.uuid) ? (<span className="hidden lg:block text-brand-secondary">{i18n['course.finishedLecture']}</span>) : (<span className="hidden lg:block">{i18n['course.startLecture']}</span>)}
                                                            </a>)}
                                                    </div>

                                                </div>
                                            </li>))}
                                    </ul>
                                </li>)}
                        </ul>
                    </Disclosure.Panel>
                </Transition>
            </>)}
    </Disclosure>;
};
CourseModuleItem.displayName = 'CourseModuleItem';
export default CourseModuleItem;
const ItemStatus = ({ isLocked, isFinished }) => {
    const lockingClass = isLocked ? 'fa-lock' : 'fa-lock-open';
    return (<div className="flex items-center gap-x-1.5">
            {isFinished ? (<span className="text-brand-secondary">
                    <i className="fa-solid fa-check"></i>
                </span>) : (<span className="text-gray-500">
                    <i className={'fa-regular ' + lockingClass}></i>
                </span>)}
        </div>);
};
